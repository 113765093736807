import React from 'react';
import {
  Input as ChakraInput,
  InputProps as ChakraInputProps,
} from '@chakra-ui/react';
import { getInputProps } from '../inputs.util';

export type InputProps = ChakraInputProps;

const Input = (props: InputProps) => <ChakraInput {...getInputProps(props)} />;

export { Input };
