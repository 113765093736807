import * as React from 'react';
import { ReactNode } from 'react';
import {
  FormProvider,
  useForm,
  UseFormReturn,
  UseFormProps,
} from 'react-hook-form';
import { yupResolver, Resolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { observer } from 'mobx-react';

interface IProps<T> extends Resolver {
  onSubmit: (model: T, methods: UseFormReturn<T>) => void;
  children: ReactNode | ((data: UseFormReturn<T>) => ReactNode);
  id: string;
  schema?: yup.AnyObjectSchema;
  mode?: 'all' | 'onSubmit';
  resetOnSubmit?: boolean;
  defaultValues?: UseFormProps<T>['defaultValues'];
}

// eslint-disable-next-line @typescript-eslint/ban-types
const Form = observer(
  <T extends object>({
    onSubmit,
    schema,
    children,
    resetOnSubmit = true,
    id,
    mode = 'all',
    defaultValues,
    ...options
  }: IProps<T>) => {
    const methods = useForm<T>({
      resolver: schema ? yupResolver(schema) : undefined,
      mode,
      defaultValues,
      ...options,
    });

    /* eslint-disable @typescript-eslint/no-explicit-any */
    const handleSubmit = async (model: any) => {
      await onSubmit(model, methods);
      resetOnSubmit && methods.reset();
    };

    const onError = (errors) => console.log(errors);

    return (
      <FormProvider {...methods}>
        <form
          noValidate
          id={id}
          onSubmit={methods.handleSubmit(handleSubmit, onError)}>
          {typeof children === 'function' ? children(methods) : children}
        </form>
      </FormProvider>
    );
  },
);

export { Form };
